<template>
  <div class="hero">
    <div class="hero-top">
      <img src="./../assets/osnova-boja.png" alt="" />
      <h1>Za Telepski park - poslednji spas</h1>
    </div>
    <p class="website-desc">
      <b>
        Da li je u redu da stanovnici naselja Telep u Novom Sadu ostanu bez parka?
      </b>
    </p>
    <p class="website-desc">
      
      Ukoliko je vaš odgovor <b>NE</b>, ovo je prilika da nadležnima zajedno
      stavimo do znanja naše nezadovoljstvo zbog otimanja parka koja je ujedno i
      poslednje zelene površine naTelepu.
      <br />
      <br />
      <b
        >Hajde da preko ove aplikacije pošaljemo što više mejlova gradskim
        vlastima i da se na taj način čuje glas građana, kao i da nadležne
        podsetimo da treba da služe nama, a ne pojedinačnim interesima i
        profitu.</b
      >
    </p>
    <p class="website-desc" style="font-size: 1em">
      Izaberite jednu od opcija za naslov, a naslov možete i sami menjati po
      sopstvenom nahođenju. <br />Izborom jedne opcije birate tekst koji želite
      da pošaljete, a taj tekst takođe možete modifikovati ili napisati svojim
      rečima, što vas pozivamo da učinite! Napišite svoje mišljenje svojim
      rečima!
      <br>
      <br>
      <b>
        Budite kreativni, bez uvreda, psovanja ili neprimerenih komentara. Naš
        cilj je da nas čuju, da zajedno, svako na svoj način objasni nadležnima da
        građani Telepa imaju svako pravo na park.
      </b>
    </p>
  </div>
</template>

<script>
export default {
  name: "HeroSection",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
h1 {
  font-size: 2em;
  color: #182338;
  margin: 10px 0;
  font-weight: 900;
  /* text-align: justify; */
  max-width: 500px;
}
.website-desc {
  width: 100%;
  margin: 10px auto;
  max-width: 510px;
  text-align: justify;
  /* max-width: 50%; */
  font-size: 1.3em;
  /* font-size: 19px; */
}
.hero-top {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.hero-top img {
  width: 50px;
  height: 50px;
  margin: 10px 0;
}
.instructions {
  margin-top: 50px;
  font-weight: 600;
}
@media only screen and (max-width: 600px) {
  .website-desc {
    max-width: 90%;
    text-align: justify;
    font-size: 16px;
  }
}
</style>
