<template>
    <div class="hero">
    </div>
  </template>
  
  <script>
  export default {
    name: "ExamplesSection",
    data() {
      return {};
    },
    methods: {},
  };
  </script>
  
  <style scoped></style>
  