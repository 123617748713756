<template>
  <div class="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <HeroSection></HeroSection>
    <ExamplesSection></ExamplesSection>
    <MainVue></MainVue>

    <p>Sva prava zadržava Kreni-Promeni</p>
  </div>
</template>

<script>
import MainVue from "./components/MainVue.vue";
import HeroSection from "./components/HeroSection.vue";
import ExamplesSection from "./components/ExamplesSection.vue";

export default {
  name: "App",
  components: {
    MainVue,
    HeroSection,
    ExamplesSection,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
}
body {
  background: #f1f5f8;
  margin: 0;
  padding: 10px;
  height: 100%;
}

</style>
