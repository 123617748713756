<template>
  <div class="hello">
    <div class="container">
      <div class="form" v-if="!msgSent">
        <!-- <div class="container-item">
          <label>Vaše ime</label>
          <input
            type="text"
            v-model="name"
            name="name"
            placeholder="Vaše ime"
          />
          <p v-if="nameError" class="error">Ovo polje je obavezno!</p>
        </div>
        <div class="container-item">
          <label>Vaša email adresa</label>
          <input
            type="email"
            v-model="userEmail"
            name="email"
            placeholder="Vaš email"
          />
          <p v-if="emailError" class="error">Ovo polje je obavezno!</p>
        </div> -->
        <div class="container-item">
          <label>Naslov email-a</label>
          <p>
            Klikom na dugmiće ispod možete izabrati neke od predloga naslova
          </p>
          <div class="buttons">
            <button class="option-btn" @click="changeSubject(naslovi[0])">
              Naslov 1
            </button>
            <button class="option-btn" @click="changeSubject(naslovi[1])">
              Naslov 2
            </button>
            <button class="option-btn" @click="changeSubject(naslovi[2])">
              Naslov 3
            </button>
          </div>
          <p class="disclamer">
            *ovo su generički primeri, pozivamo ih da ih klikom preuredite ili u
            potponosti sami napišite, kako bi svaki izgledao drugačije!
          </p>
          <input
            type="text"
            v-model="subject"
            name="subject"
            placeholder="Naslov email-a"
          />
          <p v-if="subjectError" class="error">Ovo polje je obavezno!</p>
        </div>
        <div class="container-item">
          <label>Poruka</label>

          <p>Klikom na dugmiće ispod možete izabrati neke od predloga poruka</p>
          <div class="buttons">
            <button class="option-btn" @click="changeMsg(poruke[0])">
              Poruka 1</button
            >.
            <button class="option-btn" @click="changeMsg(poruke[1])">
              Poruka 2
            </button>
            <button class="option-btn" @click="changeMsg(poruke[2])">
              Poruka 3
            </button>
          </div>

          <p class="disclamer">
            *ovo su generički primeri, pozivamo ih da ih klikom preuredite ili u
            potponosti sami napišite, kako bi svaki izgledao drugačije!
          </p>
          <textarea
            name="message"
            v-model="message"
            cols="30"
            rows="5"
            placeholder="Poštovani..."
          >
          </textarea>
          <p v-if="msgError" class="error">Ovo polje je obavezno!</p>
        </div>
        <div class="container-item">
          <a
            v-bind:href="
              'mailto:skupstinagrada@novisad.rs, milan.djuric@novisad.rs, milovan.amidzic@novisad.rs, urbanizam@uprava.novisad.rs, zivotnasredina@uprava.novisad.rs, snezana.sreckov@novisad.rs, igor.crnobarac@novisad.rs?subject=' +
              subject +
              '&body=' +
              message
            "
            class="sendBtn"
            >Pošalji email preko svog naloga</a
          >
          <p class="website-desc">Gradsku vlast plaćamo mi i zato crpimo pravo da im se obratimo sa zahtevom da se slušaju naše potrebe. Možda nas ne čuju kada im se obraćamo pojedinačno, ali svi zajedno jesmo glas koji nije moguće preskočiti, svi zajedno jesmo onaj glas koji donosi razliku! </p>
          <p class="disclamer">
            Ne odgovaramo za neprimerene komentare, uvrede i moguće zloupotrebe
            ovog kanala za pisanje gradskim vlastima. Apelujemo na građane da i ovom
            prilikom budemo dostojanstveni i pristojni, da budemo iskreni i
            istrajni, ali da ne dozvolimo bilo kakve uvrede, pretnje i
            neprimerene komentare. Želimo pristojnu Srbiju i za nju se borimo na
            takav način!
          </p>
        </div>
      </div>
      <div v-if="msgSent" class="msgSent">
        <h3>Hvala vam što ste poslali poruku RTS-u!</h3>
        <a href="#" class="sendBtn" @click="sendAgain()">Pošalji ponovo</a>
      </div>
      <!-- <p class="last-p">
        last p
      </p> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MainVue",
  data() {
    return {
      emailCountNumber: 0,
      name: "",
      userEmail: "",
      email: "",
      subject: "",
      message: "",
      msgSent: false,
      naslovi: [
        "Život na Telepu nema budućnost ako nema i park!",
        "Zašto samo Telep nema svoj park?",
        "U parku DA rastu deca, a NE zgrade!",
      ],
      poruke: [
        "Već šest godina stanovnici Telepa se bore za park. Vaša obaveza je da služite građanima, a ne pojedinačnim interesima i profitu.Zahtevamo da se poništi prodaja sporne parcele, a da se investitoru vrati novac ili da investitor dobije zamensku parcelu, te da Telepčani konačno dobiju dugo očekivani park.",
        "Saglasni smo sa Gradskom upravom za zaštitu žitvotne sredine, da su parkovi pluća grada. Zato vas molimo da i Telep dobije svoja pluća, kako bi svi mogli da dišemo.",
        "Nedopustivo je da građani Telepa ostanu bez poslednje zelene površine. Zahtevamo da poslušate glas građana i da u najkraćem roku nađete rešenje i obezbedite park na Telepu! Pokažite da slušate potrebe građana!",
      ],
      nameError: false,
      emailError: false,
      subjectError: false,
      msgError: false,
    };
  },
  methods: {
    changeSubject: function (subject) {
      this.subject = subject;
    },
    changeMsg: function (msg) {
      this.message = msg;
    },
    sendEmail: function () {
      if (this.name !== "") {
        this.nameError = false;
        if (this.userEmail !== "") {
          this.emailError = false;
          if (this.subject !== "") {
            this.subjectError = false;
            if (this.message !== "") {
              this.msgError = false;
              axios
                .get(
                  "https://hooks.zapier.com/hooks/catch/9841582/bcymshf/?name='" +
                    this.name +
                    "'&userEmail='" +
                    this.userEmail +
                    "'&subject='" +
                    this.subject +
                    "'&msg=" +
                    this.message +
                    "'"
                )
                .then((response) => {
                  console.log(response);
                  axios
                    .get(
                      "https://podeli-promeni-backend.herokuapp.com/email-counts/63338b2d51d08227904f7a7c"
                    )
                    .then((response) => {
                      axios.put(
                        "https://podeli-promeni-backend.herokuapp.com/email-counts/63338b2d51d08227904f7a7c",
                        {
                          "count" : parseInt(response.data.count) + 1,
                        }
                      ).then(()=>{
                        this.getEmailCount();
                      });
                    });
                });
              this.msgSent = true;
            } else {
              this.msgError = true;
            }
          } else {
            this.subjectError = true;
          }
        } else {
          this.emailError = true;
        }
      } else {
        this.nameError = true;
      }
    },
    sendAgain: function () {
      this.message = "";
      this.subject = "";
      this.msgSent = false;
    },
    getEmailCount: function () {
      axios
        .get(
          "https://podeli-promeni-backend.herokuapp.com/email-counts/63338b2d51d08227904f7a7c"
        )
        .then((response) => {
          return (this.emailCountNumber = response.data.count);
        });
    },
  },
  beforeMount() {
    this.getEmailCount();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* max-width: 900px;
  margin: 0 auto; */
}
.container .container-item input,
textarea {
  display: block;
  padding: 10px 0px;
  padding-left: 10px;
  font-size: 15px;
  width: 100%;
  max-width: 500px;
}
textarea {
  min-height: 150px;
}
label {
  text-align: left;
  display: block;
  width: 100%;
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 10px;
  max-width: 510px;
  font-weight: bolder;
}
.container-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.buttons {
  display: flex;
  font-size: 15px;
  width: 100%;
  max-width: 510px;
  margin-left: -5px;
}
.buttons button {
  padding: 7px;
  font-size: 15px;
  font-weight: bolder;
  border-radius: 5px;
  margin-right: 10px;
}
.buttons button:hover {
  cursor: pointer;
}
.sendBtn {
  background: #14a466;
  border-radius: 5px;
  color: white;
  font-size: 17px;
  font-weight: bolder;
  padding: 15px;
  border: none;
  margin-top: 15px;
  display: inline-block;
  text-decoration: none;
}
.sendBtn:hover {
  cursor: pointer;
}
p {
  text-align: left;
  display: block;
  width: 100%;
  font-size: 14px;
  max-width: 510px;
  font-weight: bolder;
}
.disclamer {
  color: #fc7f80;
  text-align: justify;
}
.option-btn {
  background-color: #182338;
  color: white;
  border: none;
}
.error {
  color: #f74343;
  font-style: italic;
}
.disclamer {
  font-style: italic;
  color: #f74343;
}
.msgSent h3 {
  margin-top: 20px;
}
.last-p {
  width: 100%;
  margin: 10px auto;
  max-width: 510px;
  /* padding: 0 10px; */
  text-align: justify;
  font-size: 1em;
  font-weight: 400;
}
.msg{
  /* font-size:  */
  text-align: center;
}
.countNumber {
  margin-top: 5px;
  font-size: 1.3em;
  font-weight: 900;
}
.website-desc {
  width: 100%;
  margin: 10px auto;
  max-width: 510px;
  text-align: justify;
  /* max-width: 50%; */
  font-size: 1em;
  /* font-size: 19px; */
}
</style>
